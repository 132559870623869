// Section : Features
// Style : Three Columns Features Section

// import node module libraries
import { Col, Container, Row } from "react-bootstrap";

// import MDI icons
import {
  mdiAccountGroup,
  mdiAnchor,
  mdiHandshakeOutline,
  mdiHumanGreetingProximity,
  mdiShieldOutline,
  mdiTrophy,
} from "@mdi/js";

// import custom components
import FeatureTopIconCard from "components/marketing/common/features/FeatureTopIconCard";

// import sub components
import SectionHeading from "./SectionHeading";

const Features3Columns = () => {
  const title = "Nuestros valores";
  const description = `Nuestros valores son los que nos hacen ser los mejores en lo que hacemos`;

  const features = [
    {
      id: 1,
      icon: mdiAnchor,
      title: "Integridad",
      description: ``,
    },
    {
      id: 2,
      icon: mdiAccountGroup,
      title: "Respeto",
      description: ``,
    },
    {
      id: 3,
      icon: mdiHumanGreetingProximity,
      title: "Trabajo en equipo",
      description: ``,
    },
    {
      id: 4,
      icon: mdiShieldOutline,
      title: "Confianza",
      description: ``,
    },
    {
      id: 5,
      icon: mdiTrophy,
      title: "Excelencia",
      description: ``,
    },
    {
      id: 6,
      icon: mdiHandshakeOutline,
      title: "Compromiso",
      description: ``,
    },
  ];

  return (
    <section className="py-lg-16 py-10">
      <Container>
        <SectionHeading title={title} description={description} />
        <Row>
          {features.map((item, index) => {
            return (
              <Col md={4} sm={12} key={index}>
                <FeatureTopIconCard item={item} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Features3Columns;
