// import node module libraries
import { Col, Row } from "react-bootstrap";

const HeroContent = () => {
  return (
    <Row>
      <Col lg={{ span: 9, offset: 1 }} md={12} sm={12} className="mb-12">
        {/* caption */}
        <h1 className="display-2 fw-bold mb-3">
          Somos <span className="text-primary">Gertima</span>
        </h1>
        {/* para  */}
        <p className="h2 mb-3 ">
          Gertima S.A. de C.V. es una empresa con más de 30 años de experiencia enfocada en la
          industria del reciclaje de plástico establecida en la ciudad de Monterrey, México.
          Enfocada en el reciclaje de productos postindustriales y post consumo, es una empresa
          comprometida a ayudar a limpiar el planeta mediante su acopio, procesos y ventas de
          productos de plástico reciclado.
        </p>
        <p className="mb-0 h4 text-body lh-lg">
          Actualmente cuenta con dos plantas procesadoras en Nuevo Leon, Escobedo y Apodaca, y
          cuenta con al menos 10 puntos de acopio a lo largo de la República Mexicana. Gertima tiene
          participación a nivel mundial enfocándose en el mercado Nacional y Norteamericano, Asia y
          Europa expandiendo su red de negocio y participación en dichos continentes.
        </p>
      </Col>
    </Row>
  );
};
export default HeroContent;
