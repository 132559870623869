// Section : Features
// Style : Features with top icon

const FeatureTopIcon = ({ item }) => {
  return (
    <div className="mb-4 px-2 d-flex flex-column align-items-center">
      {/* Icon */}
      <div className="display-4 text-primary d-flex flex-column align-items-center">
        <i className={`fe fe-${item.icon}`}></i>
      </div>
      {/* Para */}
      <div className="mt-4 d-flex flex-column align-items-center">
        <h3>{item.title}</h3>
        <p className="fs-4 text-center">{item.description}</p>
      </div>
    </div>
  );
};
export default FeatureTopIcon;
