// import node module libraries
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import MDI icons
import { mdiFacebook, mdiInstagram, mdiTwitter } from "@mdi/js";
import Icon from "@mdi/react";

// import BS icons
import { GeoAlt, Telephone } from "react-bootstrap-icons";
import { useMediaQuery } from "react-responsive";

// import sub components
import { Parallax } from "react-parallax";

// import media files
import NavbarLanding from "layouts/marketing/navbars/NavbarLanding";

const Contacto = () => {
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <main>
      <NavbarLanding />
      <Parallax
        blur={{ min: -15, max: 12 }}
        bgImage={require("assets/images/gertima/3.png")}
        bgImageAlt="gertima"
        strength={-200}
        style={{ maxWidth: "100%", overflow: "hidden" }}
      >
        <div className="mask-dark py-10 px-5">
          {isMobile ? (
            <h1 className="text-center display-3 mb-5 fw-bold text-white">CONTACTO</h1>
          ) : (
            <h1 className="display-1 mb-5 fw-bold text-white">CONTACTO</h1>
          )}
        </div>
      </Parallax>
      <section className="container-fluid bg-white">
        <Row className="" style={{ minHeight: "120vh" }}>
          <Col lg={6} md={12} sm={12}>
            <div className="px-xl-20 px-md-8 px-4 py-8 py-lg-0">
              {/* content */}
              {/* <div className="d-flex justify-content-between mb-7 align-items-center">
                <Link to="/">
                  <Image src={Logo} alt="" style={{ width: "6rem" }} />
                </Link>
              </div> */}
              <div className="text-dark mt-3">
                <h1 className="display-4 fw-bold">Ponte en contacto.</h1>
                <p className="lead text-dark">
                  Por favor llena el formulario para enviarnos un mensaje.
                </p>
                {/* <div className="mt-8">
                  <p className="fs-4 mb-4">
                    If you are seeking support please visit our <Link to="#">support portal</Link>{" "}
                    before reaching out directly.
                  </p>
                
                  <p className="fs-4">
                    <Telephone size={16} className="text-primary me-2" /> + 0123-456-7890
                  </p>
                  <p className="fs-4">
                    <EnvelopeOpen size={16} className="text-primary me-2" /> hello@geekui.com
                  </p>
                  <p className="fs-4 ">
                    <GeoAlt size={17} className="text-primary me-2" /> 2652 Kooter Lane Charlotte,
                    NC 28263
                  </p>
                </div> */}

                <h3>Teléfono</h3>
                <p className="fs-4">
                  <Telephone size={16} className="text-primary me-2" /> +52 1 81 1611 6094
                </p>

                <h3 className="mt-3">Visítanos</h3>

                <Row>
                  <Col xs={12} lg={12}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230088.52590110854!2d-100.43967807424848!3d25.700012931502854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662ebeaa63898e1%3A0x418c5559f4e31fa7!2sGERTIMA%20S.A%20DE%20C.V!5e0!3m2!1sen!2smx!4v1687053953183!5m2!1sen!2smx"
                      style={{ width: "100%", height: "12rem" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <p>
                      <GeoAlt size={17} className="text-primary me-2" />
                      Planta HDPE y Oficinas Administrativas Calle D No. 500, Parque Industrial
                      Almacentro Apodaca Nuevo León CP.66637
                    </p>
                  </Col>

                  <Col xs={12} lg={12}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3591.8386760411477!2d-100.35026252459805!3d25.808895777322245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866293a47bee9683%3A0x9630a2df4fd6bd2b!2sGERTIMA%20SA%20DE%20CV%20planta%20Escobedo!5e0!3m2!1sen!2smx!4v1698809883887!5m2!1sen!2smx"
                      style={{ width: "100%", height: "12rem" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <p>
                      <GeoAlt size={17} className="text-primary me-2" />
                      Gertima S.A. DE C.V Planta Escobedo Metalúrgica 107, Parque Industrial
                      Escobedo, 66062 Cd Gral Escobedo, N.L.
                    </p>
                  </Col>
                </Row>

                {/* social media */}
                <div className="mt-9 mb-3">
                  {/*Facebook*/}
                  <Link to="#" className="text-muted me-3">
                    <Icon path={mdiFacebook} size={1} />
                  </Link>
                  {/*Twitter*/}
                  <Link to="#" className="text-muted me-3">
                    <Icon path={mdiTwitter} size={1} />
                  </Link>
                  {/*GitHub*/}
                  <Link to="#" className="text-muted ">
                    <Icon path={mdiInstagram} size={1} />
                  </Link>
                </div>
              </div>
            </div>
          </Col>

          {/* right side form section with background color */}
          <Col
            lg={6}
            className="d-lg-flex flex-column align-items-center justify-content-center w-lg-50 min-vh-lg-100 position-fixed-lg bg-cover bg-light top-0 right-0 py-0"
          >
            {/* <ContactForm /> */}

            {/* <div
              dangerouslySetInnerHTML={
                "https://us21.list-manage.com/contact-form?u=2752a799350c3f046c0831411&form_id=82a748d11ce86630d05f6139aae116aa"
              }
            ></div> */}

            <iframe
              src="https://us21.list-manage.com/contact-form?u=2752a799350c3f046c0831411&form_id=82a748d11ce86630d05f6139aae116aa"
              className="w-100 h-100"
              style={{ minHeight: "100vh" }}
            ></iframe>
          </Col>
        </Row>
      </section>
    </main>
  );
};

export default Contacto;
