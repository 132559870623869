// import node module libraries
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

// import custom components
// import DarkLightMode from "layouts/DarkLightMode";
// import DocumentMenu from "layouts/marketing/navbars/DocumentMenu";
import NavDropdownMain from "layouts/marketing/navbars/NavDropdownMain";

// import media files
import Logo from "assets/images/brand/logo/logo.png";

// import data files
import NavbarDefaultRoutes from "routes/marketing/NavbarDefault";

const NavbarLanding = ({ transparent }) => {
  const [expandedMenu, setExpandedMenu] = useState(false);

  return (
    <Fragment>
      <Navbar
        onToggle={(collapsed) => setExpandedMenu(collapsed)}
        expanded={expandedMenu}
        expand="lg"
        className={`navbar bg-light navbar-default shadow-none ${
          transparent ? "navbar-transparent" : ""
        }`}
      >
        <Container className="px-0">
          <Navbar.Brand as={Link} to="/">
            <Image src={Logo} alt="" className="logo-landing" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {NavbarDefaultRoutes.slice(1, 4).map((item, index) => {
                if (item.children === undefined) {
                  return (
                    <Nav.Link key={index} as={Link} to={item.link} className="dropdown-item">
                      {item.menuitem}
                    </Nav.Link>
                  );
                } else {
                  return (
                    <NavDropdownMain
                      item={item}
                      key={index}
                      onClick={(value) => setExpandedMenu(value)}
                      className="dropdown-item"
                    />
                  );
                }
              })}
              {/* <DocumentMenu /> */}
            </Nav>
            <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
              {/* <DarkLightMode className="me-2" /> */}
              <div className={`mt-3 mt-lg-0`}>
                <Nav.Link
                  href="https://wa.me/5218116116094?text=Hola,%20los%20encontr%C3%A9%20por%20medio%20de%20su%20p%C3%A1gina%20web"
                  // target="_blank"
                  bsPrefix="btn"
                  className="btn btn-outline-primary"
                >
                  Envíanos un whatsapp
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

// Specifies the default values for props
NavbarLanding.defaultProps = {
  transparent: false,
};

// Typechecking With PropTypes
NavbarLanding.propTypes = {
  transparent: PropTypes.bool,
};

export default NavbarLanding;
