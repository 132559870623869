// Section : Features
// Style : Four Columns Features Section

// import node module libraries
import { Col, Container, Row } from "react-bootstrap";

// import custom components
import FeatureTopIcon from "components/marketing/common/features/FeatureTopIcon";
import SectionHeadingCenter from "components/marketing/common/section-headings/SectionHeadingCenter";

const Features4Columns = () => {
  const title = "Sencillamente, somos los mejores";
  const subtitle = "¿Porqué elegirnos?";
  const description = `Nuestra empresa se basa en tres pilares fundamentales: consistencia, calidad y servicio.`;

  const features = [
    {
      id: 1,
      icon: "clock",
      title: "Consistencia",
      description: `Nuestras entregas son puntuales, para que tu negocio no se retrase.`,
    },
    {
      id: 2,
      icon: "award",
      title: "Calidad",
      description: `Manejamos materiales de calidad, para que tu producto final sea el mejor.`,
    },
    {
      id: 3,
      icon: "user",
      title: "Servicio",
      description: `Ofrecemos un servicio de calidad, con un trato personalizado y cercano.`,
    },
    // {
    // 	id: 4,
    // 	icon: 'users',
    // 	title: 'Upskill your organization',
    // 	description: `Etiam dignissim est tristique ex porta, bibendum commodo.`
    // }
  ];

  return (
    <section className="py-8 py-lg-18 bg-white">
      <Container>
        <SectionHeadingCenter title={title} description={description} subtitle={subtitle} />
        <Row>
          {features.map((item, index) => {
            return (
              <Col lg={4} md={6} sm={12} key={index}>
                <FeatureTopIcon item={item} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Features4Columns;
