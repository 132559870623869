// import media files

// import avatar media files
import a12 from "assets/images/materiales/cubeta.png";
import a7 from "assets/images/materiales/fleje.png";
import a11 from "assets/images/materiales/hdpe-iny.png";
import a5 from "assets/images/materiales/hdpe-lavado.png";
import a6 from "assets/images/materiales/hdpe-mixto.png";
import a1 from "assets/images/materiales/hdpe-natural.png";

import a13 from "assets/images/materiales/hdpe-rojo.png";
import a2 from "assets/images/materiales/pet-claro.png";
import a4 from "assets/images/materiales/pet-verde.png";
import a8 from "assets/images/materiales/porron.png";
import a10 from "assets/images/materiales/pp-iny.png";
import a3 from "assets/images/materiales/pp-natural.png";
import a9 from "assets/images/materiales/pp-rejilla.png";

export const MaterialsData = [
  {
    id: 1,
    category: "hdpe",
    image: a1,
    title: "HDPE natural en paca",
    price: 850,
  },
  {
    id: 2,
    category: "pet",
    image: a2,
    title: "PET claro en paca",
    price: 850,
  },
  {
    id: 3,
    category: "pp",
    image: a3,
    title: "PP natural en paca",
    price: 850,
  },
  {
    id: 4,
    category: "pet",
    image: a4,
    title: "PET verde lavado en caliente",
    price: 850,
  },
  {
    id: 5,
    category: "hdpe",
    image: a5,
    title: "HDPE natural lavado",
    price: 850,
  },
  {
    id: 6,
    category: "hdpe",
    image: a6,
    title: "HDPE mixto lavado",
    price: 850,
  },
  {
    id: 7,
    category: "pet",
    image: a7,
    title: "Fleje de PET molido",
    price: 850,
  },
  {
    id: 8,
    category: "hdpe",
    image: a8,
    title: "HDPE porrón lavado",
    price: 850,
  },
  {
    id: 9,
    category: "pp",
    image: a9,
    title: "PP de rejilla molida cernida",
    price: 850,
  },
  {
    id: 10,
    category: "pp",
    image: a10,
    title: "PP de inyección mixto lavado",
    price: 850,
  },
  {
    id: 11,
    category: "hdpe",
    image: a11,
    title: "HDPE de inyección molido cernido",
    price: 850,
  },
  {
    id: 12,
    category: "hdpe",
    image: a12,
    title: "HDPE de inyección cubeta blanca lavada",
    price: 850,
  },

  {
    id: 13,
    category: "hdpe",
    image: a13,
    title: "HDPE de inyección rojo molido cernido",
    price: 850,
  },
];

export default MaterialsData;
