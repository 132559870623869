import { v4 as uuid } from "uuid";

// import media files
// import AirbnbLogo from "assets/images/brand/gray-logo-airbnb.svg";
// import IntercomLogo from "assets/images/brand/gray-logo-intercom.svg";
// import NetflixLogo from "assets/images/brand/gray-logo-netflix.svg";
// import PinterestLogo from "assets/images/brand/gray-logo-pinterest.svg";
// import StripeLogo from "assets/images/brand/gray-logo-stripe.svg";
import DiscordLogo from "assets/images/brand/logo/logo.png";

const LogoList2 = [
  {
    id: uuid(),
    logoimage: DiscordLogo,
  },
  {
    id: uuid(),
    logoimage: DiscordLogo,
  },
  {
    id: uuid(),
    logoimage: DiscordLogo,
  },
  {
    id: uuid(),
    logoimage: DiscordLogo,
  },
  {
    id: uuid(),
    logoimage: DiscordLogo,
  },
  {
    id: uuid(),
    logoimage: DiscordLogo,
  },
];

export default LogoList2;
