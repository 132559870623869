// import node module libraries
import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

// import sub components
import SectionHeading from "./SectionHeading";

const Stat = () => {
  const title = "Nuestros números";
  const description = `Somos una empresa con más de 30 años de experiencia en el mercado, con presencia en más de 24 países`;

  const counters = [
    {
      id: 1,
      title: "Países",
      value: "24",
    },
    {
      id: 2,
      title: "Años de experiencia",
      value: "30+",
    },
    {
      id: 3,
      title: "Plantas",
      value: "2",
    },
    {
      id: 4,
      title: "Empleados",
      value: "100+",
    },
  ];
  return (
    <Fragment>
      <SectionHeading title={title} description={description} />
      <Row>
        {counters.map((item, index) => {
          return (
            <Col lg={3} md={6} sm={6} xs={6} key={index}>
              {/* Counter */}
              <div className="border-top pt-4 mt-6 mb-5">
                <h1 className="display-3 fw-bold mb-0">{item.value}</h1>
                <p className="text-uppercase text-muted">{item.title}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default Stat;
