// import node module libraries
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import YoutubeBackground from "react-youtube-background";

// import custom components
// import LogosTopHeadingInverseDark from "components/marketing/common/clientlogos/LogosTopHeadingInverseDark";
// import { Parallax } from "react-parallax";

// import MDI icons

// import media files
// import GradientBG from "assets/images/background/gradient-bg.png";

// import data files

const HeroGradient = () => {
  const isLaptop = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <section
      className="position-relative bg-cover"
      //   style={{ backgroundImage: `url(${GradientBG})`, height: "100vh" }}
    >
      {/* Image */}
      {/* 
      <Parallax
        blur={{ min: -15, max: 9 }}
        bgImage={require("assets/images/brand/pollution.jpg")}
        bgImageAlt="gertima"
        strength={-200}
        style={{ maxWidth: "100%", overflow: "hidden" }}
      > */}
      <YoutubeBackground
        videoId={"HPOwZLgRynY"}
        className="w-100"
        style={{ maxWidth: "100%", overflow: "hidden" }}
      >
        <div className="mask-dark">
          <Container>
            <Row className="align-items-center bg-pic">
              <Col lg={5} xs={12} className="order-md-2">
                {/* <div
                  className="mb-2 mb-md-0 d-flex align-items-center justify-content-center"
                  style={{ position: "relative" }}
                >
                  <Image
                    src={Graphics}
                    alt=""
                    className={`shadow-lg ${isLaptop ? "" : "mw-lg-130"}`}
                    style={{
                      width: "30rem",
                      borderRadius: "16px",
                      position: "absolute",
                      bottom: "-1rem",
                      left: "5rem",
                    }}
                  />

                  <Image
                    src={Graphics2}
                    alt=""
                    className={`shadow-lg ${isLaptop ? "" : "mw-lg-130"}`}
                    style={{
                      width: "24rem",
                      borderRadius: "16px",
                      position: "absolute",
                      top: "-7rem",
                      right: "4rem",
                    }}
                  />
                </div> */}
              </Col>
              <Col lg={7} xs={12} className="order-md-1 ">
                {/* Heading */}
                {isMobile ? (
                  <h1 className="text-center display-3 mb-5 fw-bold text-white">
                    AYUDANDO A LIMPIAR EL PLANETA MEDIANTE EL RECICLAJE
                  </h1>
                ) : (
                  <h1 className="display-1 mb-5 fw-bold text-white">
                    AYUDANDO A LIMPIAR EL PLANETA MEDIANTE EL RECICLAJE
                  </h1>
                )}

                {/* list */}
                {/* <ListGroup bsPrefix="list-unstyled" className="fs-3 text-dark mb-6 fw-medium">
              <ListGroup.Item bsPrefix="mb-1" className="d-flex">
                <Icon path={mdiCheckCircle} size={0.9} className="text-success mt-1 me-2" /> Simple
                to use, beautiful UI design
              </ListGroup.Item>
              <ListGroup.Item bsPrefix="mb-1" className="d-flex">
                <Icon path={mdiCheckCircle} size={0.9} className="text-success mt-1 me-2" />{" "}
                Complete complex project with ease
              </ListGroup.Item>
              <ListGroup.Item bsPrefix="mb-1" className="d-flex">
                <Icon path={mdiCheckCircle} size={0.9} className="text-success mt-1 me-2" /> An
                intuitive admin app for developers
              </ListGroup.Item>
            </ListGroup> */}

                {/* Buttons */}
                {/* <div className="mb-8 mb-lg-0">
              <Link to="#" className="btn btn-primary me-3 mb-2 mb-lg-0">
                Get started for Free
              </Link>
              <Link to="#" className="text-nowrap btn-link">
                Questions? Talk to an expert
              </Link>
            </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </YoutubeBackground>
      {/* </Parallax> */}

      {/* Trusted By logo */}
      <Container>
        {/* <LogosTopHeadingInverseDark
          title="TRABAJAMOS CON LAS MEJORES EMPRESAS"
          logos={LogoList1}
          limit={5}
          inverse={false}
        /> */}
      </Container>
    </section>
  );
};
export default HeroGradient;
