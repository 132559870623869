// import node module libraries
import { Fragment, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

// import sub components
import Features4Columns from "./Features4Columns2";
import HeroGradient from "./HeroGradient";
// import AppIntegration from './AppIntegration';
// import SimplePricingPlans from './SimplePricingPlans';
// import HeroLeftImage from './HeroLeftImage';
// import HeroRightImage from './HeroRightImage';
import CTALightBG from "components/marketing/common/call-to-action/CTALightBG";

// import layouts
import FooterCenter from "layouts/marketing/footers/FooterWithLinks";
import NavbarLanding from "layouts/marketing/navbars/NavbarLanding";
import BrowseCategories from "./BrowseCategories";

const LandingSass = () => {
  useEffect(() => {
    document.body.className = "bg-white";
  });

  useEffect(() => {
    // Check if the current pathname contains '#materiales'
    console.log("window.location.href", window.location);
    if (window.location.href.includes("#materiales") || window.location.hash === "#materiales") {
      console.log("scrolling to #materiales");

      const targetElement = document.getElementById("materiales");

      // Scroll to the target element if it exists
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash]);

  const title = "Nos encantaría trabajar contigo";
  const subtitle = "Contáctanos";
  const description = `Envíanos un mensaje o correo electrónico`;
  const btntext = "Contáctanos";
  const btnlink = "/contacto";
  const counters = [
    {
      id: 1,
      title: "Países",
      value: "24",
    },
    {
      id: 2,
      title: "Años de experiencia",
      value: "30+",
    },
    {
      id: 3,
      title: "Plantas",
      value: "2",
    },
    {
      id: 4,
      title: "Empleados",
      value: "100+",
    },
  ];
  return (
    <Fragment>
      {/* Landing Page Navbar */}
      <NavbarLanding transparent />

      <main>
        {/* hero gradient */}
        <HeroGradient />

        {/* features */}
        <Features4Columns />
        <Container>
          <Row>
            {counters.map((item, index) => {
              return (
                <Col lg={3} md={6} sm={6} xs={6} key={index}>
                  {/* Counter */}
                  <div className="border-top pt-4 mt-6 mb-5">
                    <h1 className="display-3 fw-bold mb-0">{item.value}</h1>
                    <p className="text-uppercase text-muted">{item.title}</p>
                  </div>
                </Col>
              );
            })}{" "}
          </Row>
        </Container>

        <BrowseCategories />
        {/* image sections */}
        {/* <section className="pb-lg-16 pb-8">
					<Container>
						<HeroLeftImage />

						<hr className="my-lg-12 my-8" />

						<HeroRightImage />
					</Container>
				</section> */}

        {/* <AppIntegration /> */}

        {/* <section className="pt-lg-16 pb-lg-14 pt-8 pb-8">
          <Container>
            <CustomersTestimonials />

            <hr className="my-lg-12 my-8" />

          
             <SimplePricingPlans /> 
          </Container>
        </section> */}

        {/* cta with bg color  */}
        <CTALightBG
          title={title}
          description={description}
          subtitle={subtitle}
          btntext={btntext}
          btnlink={btnlink}
        />
      </main>

      {/* Footer with center alignment */}
      <FooterCenter />
    </Fragment>
  );
};

export default LandingSass;
