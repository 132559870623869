// import node module libraries
import { Container } from "react-bootstrap";

// import custom components
import SectionHeadingLeft from "components/marketing/common/section-headings/SectionHeadingLeft";

// import sub components
import CoursesTabSlider from "./CoursesTabSlider";

const BrowseCategories = () => {
  const title = "Manejamos los mejores materiales";
  const subtitle = "Materiales";
  const description = `Nuestros materiales son de la mejor calidad, para que tu producto final sea el mejor.`;

  return (
    <section className="py-8 py-lg-16 pt-4 bg-light-gradient-bottom bg-white" id="materiales">
      <Container>
        <SectionHeadingLeft title={title} description={description} subtitle={subtitle} />
        <CoursesTabSlider />
      </Container>
    </section>
  );
};
export default BrowseCategories;
